import * as React from 'react';

function Pentagon() {
  return (
    <svg
      height="200"
      width="200"
      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}
    >
      <polygon
        points="100,10 190,60 150,180 50,180 10,60"
        stroke="indigo"
        strokeWidth="2"
        fill="transparent"
        style={{
          strokeDasharray: 800,
          strokeDashoffset: 800,
          animation: `dash 5s linear forwards`
        }}
      />
    </svg>
  );
}

export default Pentagon;
