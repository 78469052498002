import React, { useEffect, useState, useRef } from 'react';
import Circle from './shapes/Circle';
import Square from './shapes/Square';
import Pentagon from './shapes/Pentagon';
import Triangle from './shapes/Triangle';
import './App.css';

const shapes = [Circle, Square, Pentagon, Triangle];
const interval = 1000; // Change this value to adjust the interval between shapes

function App() {
  const [displayedShapes, setDisplayedShapes] = useState([]);
  const [circlePosition, setCirclePosition] = useState('50%'); 

  // Create a persistent index reference
  const indexRef = useRef(0);

  useEffect(() => {
    let circleCount = 0;

    const timer = setInterval(() => {
      // Use indexRef.current instead of index
      if (indexRef.current > 100) {
        clearInterval(timer); 
        return; 
      }

      if (shapes[indexRef.current % shapes.length] === Circle) {
        if (circleCount >= 7) {
          indexRef.current += 1;
          return;
        }
        circleCount += 1;
      }

      setDisplayedShapes(oldShapes => [...oldShapes, shapes[indexRef.current % shapes.length]]);
      indexRef.current += 1; // Update the index reference
    }, interval);

    return () => clearInterval(timer);
  }, []);


  return (
    <div className="App" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgb(204, 119, 34)', overflow: 'hidden', position: 'relative' }}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 3 }}>
        <h1 style={{ fontFamily: 'Quicksand, sans-serif', fontSize: '15vw', margin: '0', color: 'white', textShadow: '0 0 20px rgba(255, 215, 0, 0.8)' }}>Mandala</h1>

        <p style={{ fontFamily: 'Quicksand, sans-serif', fontSize: '4rem', margin: '0', color: 'black', textShadow: '0 0 20px rgba(255, 255, 255, 0.8)' }}>Beta Coming Soon</p>
      </div>

      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2, pointerEvents: 'none' }}>
      {displayedShapes.map((Shape, index) => {
        const rotation = `rotate(${index * 15}deg)`;
        const filter = 'drop-shadow(0 10px 20px rgba(218, 165, 32, 0.4))';

        const shapeStyle = {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%) ${rotation}`,
          transformOrigin: 'center',
          filter: filter,
        };

        return (
          <div key={index} style={shapeStyle}>
            <Shape />
          </div>
        );
      })}

      </div>
    </div>
  );
}

export default App;
