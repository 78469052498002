import * as React from 'react';


function Triangle() {
  return (
    <svg height="200" width="200" style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2}}>
      <polygon 
        points="100,10 190,190 10,190" 
        stroke="gold" strokeWidth="2" 
        fill="transparent" 
        style={{
            strokeDasharray: 800,
            strokeDashoffset: 800,
            animation: `dash 5s linear forwards`
          }}/>
    </svg>
  );
}

export default Triangle;
