import * as React from 'react';


function Square() {
  return (
    <svg
      width="200"
      height="200"
      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid meet"
    >
      <rect
        x="10"
        y="10"
        width="80"
        height="80"
        fill="transparent"
        stroke="red"
        strokeWidth="2"
        style={{
          strokeDasharray: 400,
          strokeDashoffset: 400,
          animation: `dash 5s linear forwards`
        }}
      />
    </svg>
  );
}

export default Square;
