import * as React from 'react';

function Circle() {
  return (
    <svg
    width="63vw"
    height="63vw"
    style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid meet"
    >
      <circle
        cx="50"
        cy="50"
        r="40"
        fill="transparent"
        stroke="blue"
        strokeWidth="1"
        style={{
          strokeDasharray: 250,
          strokeDashoffset: 250,
          animation: `dash 5s linear forwards`
        }}
      />
    </svg>
  );
}

export default Circle;
